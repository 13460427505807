import React from 'react'
import styled from 'styled-components'
import {SRLWrapper} from 'simple-react-lightbox'
import Fade from 'react-reveal/Fade'
import Container from './../components/container'
import GalleryGridItem from './../components/gallery-grid-item'
import {PATH_TO_STATIC_IMAGES, SIMPLE_REACT_LIGHTBOX_OPTIONS} from './../constants/constants'
import {stripMarkup} from './../helper-functions/strip-markup'
import StephenHawkingInfo from './../components/stephen-hawking-info'
import {getImageSrc} from './../helper-functions/get-image-src'

export default function GalleryGrid({
  buyNowButtonImageUrl = '',
  classModifier,
  fallbackImgUrl,
  imageData,
  imagesAreForSale = false,
  isPublicCollection = false,
  prompotedImageData = [],
  staticImagesFolderName,
  purchaseEmailAddress = '',
  purchaseEmailSubjectLine = '',
  purchaseMethod = '',
  showStephenHawkingImageAndInfo = false,
}) {
  const getAllTextUnformatted = function (title, supplementaryInfo) {
    let allTextUnformatted = stripMarkup(title)
    if (stripMarkup(supplementaryInfo)) {
      allTextUnformatted = `${allTextUnformatted}, ${stripMarkup(supplementaryInfo)}`
    }
    return allTextUnformatted
  }

  return (
    <StyledComponent className={`gallery-grid ${classModifier ? `gallery-grid--${classModifier}` : ''}`}>
      <Container className="gallery-grid__container">
        <SRLWrapper options={SIMPLE_REACT_LIGHTBOX_OPTIONS}>
          {prompotedImageData &&
            showStephenHawkingImageAndInfo &&
            prompotedImageData.map((item, index) => (
              <Fade key={`gallery-grid-item-promoted-${index}`}>
                <GalleryGridItem
                  allTextUnformatted={getAllTextUnformatted(item.title, item.supplementary_info)}
                  buyNowButtonImageUrl={buyNowButtonImageUrl}
                  externalUrl={item.externalUrl}
                  fallbackImgUrl={fallbackImgUrl}
                  fullsizeSrc={getImageSrc(item.fullUrl, staticImagesFolderName, item.filename)}
                  imageIsForSale={imagesAreForSale}
                  isAvailable={item.isAvailable}
                  orientation={item.orientation}
                  paypalButtonId={item.paypalButtonId}
                  priceFormatted={item.price_formatted}
                  purchaseEmailAddress={purchaseEmailAddress}
                  purchaseEmailSubjectLine={purchaseEmailSubjectLine}
                  purchaseMethod={purchaseMethod}
                  supplementaryInfo={item.supplementary_info}
                  supplementaryInfoPrefix={item.supplementary_info_prefix}
                  supplementaryInfoSuffix={item.supplementary_info_suffix}
                  thumbnailSrc={getImageSrc(item.thumbnailUrl, staticImagesFolderName, item.thumbnail)}
                  title={item.title}
                />
              </Fade>
            ))}

          {showStephenHawkingImageAndInfo && (
            <Fade>
              <StephenHawkingInfo />
            </Fade>
          )}

          {imageData.map((item, index) => (
            <Fade key={`gallery-grid-item-${index}`}>
              <GalleryGridItem
                allTextUnformatted={getAllTextUnformatted(item.title, item.supplementary_info)}
                buyNowButtonImageUrl={buyNowButtonImageUrl}
                externalUrl={item.externalUrl}
                fallbackImgUrl={fallbackImgUrl}
                fullsizeSrc={getImageSrc(item.fullUrl, staticImagesFolderName, item.filename)}
                imageIsForSale={imagesAreForSale}
                isAvailable={item.isAvailable}
                orientation={item.orientation}
                paypalButtonId={item.paypalButtonId}
                price={item.price}
                priceFormatted={item.price_formatted}
                purchaseEmailAddress={purchaseEmailAddress}
                purchaseEmailSubjectLine={purchaseEmailSubjectLine}
                purchaseMethod={purchaseMethod}
                supplementaryInfo={item.supplementary_info}
                supplementaryInfoPrefix={item.supplementary_info_prefix}
                supplementaryInfoSuffix={item.supplementary_info_suffix}
                thumbnailSrc={getImageSrc(item.thumbnailUrl, staticImagesFolderName, item.thumbnail)}
                title={item.title}
              />
            </Fade>
          ))}
        </SRLWrapper>
      </Container>
    </StyledComponent>
  )
}

const StyledComponent = styled.div`
  .gallery-grid__container {
    > div {
      column-gap: 2rem;
      display: grid;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      row-gap: 2rem;

      @media (min-width: 768px) {
        column-gap: 8rem;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        row-gap: 8rem;
      }

      @media (min-width: 1200px) {
        column-gap: 8rem;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        row-gap: 8rem;
      }
    }
  }
`
