import React from 'react'

export default function ExternalLinkSvg({fill, className = ''}) {
  return (
    <svg x="0" y="0" viewBox="0 0 1000 1000" fill={fill} stroke="#000" className={className}>
      <path d="M609 13c-23 9-28 21-28 66 0 29 1 34 6 45 11 21 14 22 97 24l72 1-187 187a8495 8495 0 00-192 195c-4 9-4 24 0 33l31 34c24 24 27 26 38 27h20c5-2 66-60 196-191l191-188c1 0 2 33 2 73 0 71 0 73 6 81 11 17 19 19 63 18 43-1 47-2 59-19 6-7 6-10 6-180l1-174-6-12c-4-6-10-14-15-17-9-5-14-5-180-6-137 0-171 0-180 3z" />
      <path d="M100 121c-30 5-58 25-73 51-18 29-17 11-17 390l1 344 9 18c9 20 28 41 44 51 27 16 9 15 381 15 307 0 344-1 357-4 38-12 68-44 76-83 2-10 3-82 3-236V445H746v409H145V257h409V118l-219 1c-121 0-227 1-235 2z" />
    </svg>
  )
}
