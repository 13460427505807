import React from 'react'
import styled from 'styled-components'
import ExternalLinkSvg from './../components/external-link-svg'
import PaypalButton from './../components/paypal-button'
import {
  SHOW_BUY_PRINTS_BUTTONS,
  PURCHASE_BODY_TEXT,
  PURCHASE_BODY_TEXT_TITLE_PLACEHOLDER,
  PURCHASE_BODY_TEXT_PRICE_PLACEHOLDER,
  PURCHASE_METHOD_EMAIL,
  PURCHASE_METHOD_PAYPAL,
} from './../constants/constants'

export default function GalleryGridItem({
  allTextUnformatted = '',
  buyNowButtonImageUrl = '',
  externalUrl = null,
  fallbackImgUrl = '',
  fullsizeSrc = '',
  imageIsForSale = false,
  isAvailable = true,
  orientation = '',
  priceFormatted = '',
  supplementaryInfo = '',
  supplementaryInfoPrefix = '',
  supplementaryInfoSuffix = '',
  thumbnailSrc = '',
  title = '',
  paypalButtonId = '',
  purchaseEmailAddress = '',
  purchaseEmailSubjectLine = '',
  purchaseMethod = '',
}) {
  let buyLinkHref = ''
  if (!SHOW_BUY_PRINTS_BUTTONS) {
    imageIsForSale = false
  }
  if (purchaseMethod === PURCHASE_METHOD_EMAIL) {
    const subjectEncoded = encodeURIComponent(purchaseEmailSubjectLine)
    const bodyEncoded = encodeURIComponent(
      PURCHASE_BODY_TEXT.replace(PURCHASE_BODY_TEXT_TITLE_PLACEHOLDER, title).replace(
        PURCHASE_BODY_TEXT_PRICE_PLACEHOLDER,
        priceFormatted
      )
    )
    buyLinkHref = `mailto:${purchaseEmailAddress}?subject=${subjectEncoded}&body=${bodyEncoded}`
  }
  return (
    <StyledComponent className={`gallery-grid-item ${imageIsForSale ? 'gallery-grid-item--for-purchase' : ''}`}>
      <figure>
        <a
          // href={externalUrl || fullsizeSrc}
          href={fullsizeSrc}
          data-attribute={externalUrl ? '' : 'SRL'}
          target={externalUrl ? '_blank' : ''}
          rel={externalUrl ? 'noopener' : ''}
        >
          <img
            src={thumbnailSrc || fallbackImgUrl}
            loading="lazy"
            alt={allTextUnformatted}
            className={`thumbnail thumbnail--${orientation.toLowerCase()}`}
          />
        </a>
        <figcaption>
          {externalUrl ? (
            <a href={externalUrl} className="external-link" target="_blank">
              <span className="thumbnail-info-text" dangerouslySetInnerHTML={{__html: title}} />
              <ExternalLinkSvg className="external-link-icon" fill="#666" />
            </a>
          ) : (
            <span className="thumbnail-info-text" dangerouslySetInnerHTML={{__html: title}} />
          )}
          {supplementaryInfoPrefix && (
            <p
              className="supplementary-info supplementary-info--prefix"
              dangerouslySetInnerHTML={{__html: supplementaryInfoPrefix}}
            />
          )}
          {supplementaryInfo && <p className="supplementary-info" dangerouslySetInnerHTML={{__html: supplementaryInfo}} />}
          {supplementaryInfoSuffix && (
            <p
              className="supplementary-info supplementary-info--suffix"
              dangerouslySetInnerHTML={{__html: supplementaryInfoSuffix}}
            />
          )}

          {SHOW_BUY_PRINTS_BUTTONS && imageIsForSale && (
            <div className="purchase-info">
              <p className="price-formatted">{priceFormatted}</p>
              {purchaseMethod === PURCHASE_METHOD_EMAIL && isAvailable && (
                <a className={`btn btn--purchase btn--email`} href={buyLinkHref} target="_blank" rel="noopener">
                  Buy now
                </a>
              )}
              {purchaseMethod === PURCHASE_METHOD_PAYPAL && isAvailable && (
                <PaypalButton paypalButtonId={paypalButtonId} buyNowButtonImageUrl={buyNowButtonImageUrl} />
              )}
              {!isAvailable && <strong className="btn btn--sold-out">Sold out</strong>}
            </div>
          )}
        </figcaption>
      </figure>
    </StyledComponent>
  )
}

const StyledComponent = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 5rem;
  overflow: hidden;
  position: relative;
  transition: border-color 0.4s linear;

  @media (min-width: 768px) {
    height: 100%;
    margin-bottom: 0;
    padding-bottom: 9rem;
  }

  @media (min-width: 1400px) {
  }

  &:hover {
    figcaption {
      color: #000;
    }
  }

  &::after {
    background-color: #909090;
    bottom: 0;
    content: '';
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 4rem;
  }

  &.gallery-grid-item--for-purchase {
    padding-bottom: 0;

    @media (min-width: 768px) {
      padding-bottom: 19rem;
    }
  }

  figure {
    margin: 0;
    width: 100%;

    @media (min-width: 768px) {
      overflow: hidden;
    }

    a {
      display: flex;
      flex-direction: column;
      justify-content: center;

      &.external-link {
        color: blue;
        display: block;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .thumbnail {
    border-radius: 3px;
    display: block;
    margin-bottom: 1rem;
    width: 100%;

    &--landscape {
      @media (min-width: 768px) {
      }
      @media (min-width: 1200px) {
      }
      @media (min-width: 1470px) {
      }
    }

    &--portrait {
      @media (min-width: 768px) {
        max-height: calc((100vw - 12rem) / 2);
        width: auto;
      }
      @media (min-width: 1200px) {
        max-height: calc((100vw - 58rem) / 2);
      }
      @media (min-width: 1300px) {
        max-height: calc((100vw - 58rem) / 2);
      }
      @media (min-width: 1400px) {
        max-height: calc((100vw - 58rem) / 2);
      }
      @media (min-width: 1470px) {
        max-height: 40rem;
      }
    }

    &--square {
      @media (min-width: 768px) {
        max-height: calc((100vw - 30rem) / 2);
        width: auto;
      }
      @media (min-width: 1200px) {
        max-height: calc((100vw - 72rem) / 2);
      }
      @media (min-width: 1300px) {
        max-height: calc((100vw - 79rem) / 2);
      }
      @media (min-width: 1400px) {
        max-height: calc((100vw - 85rem) / 2);
      }
      @media (min-width: 1470px) {
        max-height: 31rem;
      }
    }

    @media (min-width: 768px) {
      margin: 0 auto;
    }
  }

  figcaption {
    font-size: 1.3rem;
    font-weight: 100;
    margin: 0 auto;
    max-width: 29rem;
    padding-bottom: 2rem;
    position: relative;
    text-align: center;
    transition: color 0.4s linear;
    width: 100%;

    @media (min-width: 768px) {
      bottom: 0;
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
    }
  }

  .supplementary-info {
    color: #646464;
    font-weight: 100;
    margin: 0;

    &--prefix {
      margin: 0;
    }
    &--suffix {
      margin: 0;
    }
  }

  .external-link-icon {
    height: 1.6rem;
    margin-left: 1rem;
    transform: translateY(1px);
    width: 1.6rem;
  }

  .purchase-info {
    padding-top: 5rem;
    position: relative;

    &::before {
      background-color: #909090;
      content: '';
      display: block;
      height: 2px;
      left: 50%;
      position: absolute;
      top: 2.3rem;
      transform: translateX(-50%);
      width: 4rem;
    }
  }

  .price-formatted {
    font-weight: 600;
    margin-bottom: 1rem;
  }

  .btn {
    &--purchase {
      display: inline-block;
      font-size: 1.5rem;
      font-weight: 100;
    }

    &--sold-out {
      cursor: not-allowed;
      font-size: 1.5rem;
      font-weight: 100;

      &:hover {
        background-color: #d9d9d9;
      }
    }
  }
`
