import React from 'react'
import styled from 'styled-components'

export default function Book({
  imgSrc,
  imgAlt,
  title,
  summary,
  publisher,
  isbn,
  numPages,
  weight,
  dimensions,
  buyLinkLabel1,
  buyLinkUrl1,
  buyLinkLabel2,
  buyLinkUrl2,
}) {
  return (
    <StyledComponent>
      <div className="img-warapper">
        {imgSrc && <img src={imgSrc} alt={imgAlt ? imgAlt : title} />}
        {(buyLinkLabel1 || buyLinkUrl1 || buyLinkLabel2 || buyLinkUrl2) && (
          <div className="buy-book-links buy-book-links--desktop">
            {buyLinkUrl1 && buyLinkLabel1 && (
              <a href={buyLinkUrl1} target="_blank" rel="noopener" className="btn btn--primary buy-book-link">
                {buyLinkLabel1}
              </a>
            )}
            {buyLinkUrl2 && buyLinkLabel2 && (
              <a href={buyLinkUrl2} target="_blank" rel="noopener" className="btn btn--primary buy-book-link">
                {buyLinkLabel2}
              </a>
            )}
          </div>
        )}
      </div>
      <div className="text-wrapper">
        {title && <h2 className="book-title">{title}</h2>}
        {summary && <div className="summary" dangerouslySetInnerHTML={{__html: summary}} />}
        <p className="details">
          {publisher && (
            <>
              <strong>Publisher:</strong> {publisher}
              <br />
            </>
          )}

          {isbn && (
            <>
              <strong>ISBN:</strong> {isbn}
              <br />
            </>
          )}

          {numPages && (
            <>
              <strong>Number of pages:</strong> {numPages}
              <br />
            </>
          )}

          {weight && (
            <>
              <strong>Weight:</strong> {weight}
              <br />
            </>
          )}

          {dimensions && (
            <>
              <strong>Dimensions:</strong> {dimensions}
              <br />
            </>
          )}
        </p>

        {(buyLinkLabel1 || buyLinkUrl1 || buyLinkLabel2 || buyLinkUrl2) && (
          <div className="buy-book-links buy-book-links--mobile">
            {buyLinkUrl1 && buyLinkLabel1 && (
              <a href={buyLinkUrl1} target="_blank" rel="noopener" className="btn btn--primary buy-book-link">
                {buyLinkLabel1}
              </a>
            )}
            {buyLinkUrl2 && buyLinkLabel2 && (
              <a href={buyLinkUrl2} target="_blank" rel="noopener" className="btn btn--primary buy-book-link">
                {buyLinkLabel2}
              </a>
            )}
          </div>
        )}
      </div>
    </StyledComponent>
  )
}

const StyledComponent = styled.article`
  margin-bottom: 13vw;
  display: flex;
  flex-direction: column;

  @media (min-width: 800px) {
    margin-bottom: 10.4rem;
  }

  @media (min-width: 800px) {
    flex-direction: row;
  }

  .img-warapper {
    margin-bottom: 6vw;
    width: 100%;

    @media (min-width: 800px) {
      margin-bottom: 0;
      margin-right: 6rem;
      width: 33%;
    }

    @media (min-width: 1100px) {
      width: 40%;
    }
  }

  .text-wrapper {
    width: 100%;

    @media (min-width: 800px) {
      width: 66%;
    }

    @media (min-width: 1100px) {
      width: 60%;
    }
  }

  img {
    width: 100%;
    margin: 0 auto;
    display: block;

    @media (min-width: 800px) {
      width: 100%;
    }

    @media (min-width: 1100px) {
      margin-bottom: 5.2rem;
    }
  }

  .book-title {
    margin: 0 0 6vw 0;

    @media (min-width: 800px) {
      margin-bottom: 4.8rem;
    }
  }

  .summary {
    font-weight: 100;
    margin-bottom: 6vw;

    @media (min-width: 800px) {
      margin-bottom: 4.8rem;
      max-width: 51rem;
    }
  }

  .details {
    border-left: 4px solid #666;
    font-style: italic;
    padding-left: 2rem;
    margin-bottom: 6vw;

    @media (min-width: 800px) {
      margin-bottom: 4.8rem;
    }
  }

  .buy-book-links {
    &--desktop {
      display: none;

      @media (min-width: 1100px) {
        display: block;
      }
    }
    &--mobile {
      display: block;

      @media (min-width: 1100px) {
        display: none;
      }
    }
  }

  .buy-book-link {
    display: block;
    max-width: 38rem;
    margin-bottom: 1rem;
    text-align: center;

    @media (min-width: 1100px) {
      max-width: none;
      width: 100%;
    }
  }
`
