import React from 'react'
import styled from 'styled-components'

export default function PaypalButton({paypalButtonId, buyNowButtonImageUrl}) {
  return (
    <StyledComponent action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
      <input type="hidden" name="cmd" value="_s-xclick" />
      <input type="hidden" name="hosted_button_id" value={paypalButtonId} />
      <button type="submit" class="btn btn--purchase btn--paypal">
        Buy Now
      </button>
    </StyledComponent>
  )
}

const StyledComponent = styled.form`
  .btn {
    &--purchase {
      display: inline-block;
      font-size: 1.5rem;
      font-weight: 100;
    }
  }
`
