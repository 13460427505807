import React from 'react'
import {graphql} from 'gatsby'
import Fade from 'react-reveal/Fade'
import Layout from './../components/layout'
import Container from './../components/container'
import PageHeading from './../components/page-heading'
import BodyCopy from './../components/body-copy'
import {stripMarkup} from './../helper-functions/strip-markup'
import anotherFigureImgSrc from './../images/book-another-figure-in-the-landscape.jpg'
import Book from './../components/book'
import GalleryGrid from './../components/gallery-grid'
import ScrollToTop from './../components/scroll-to-top'

export default props => {
  const {data} = props
  const prismicData = {
    booksHeading: data.prismicBooksPage.data.page_heading.text,
    books: data.prismicBooksPage.data.books,
    articlesHeading: data.prismicArticlesPage.data.page_heading.text,
    bodyCopy: data.prismicArticlesPage.data.body_copy.html,
    fallbackImgUrl: data.prismicArticlesPage.data.fallback_image.thumbnails.thumbnail.url,
    articles: data.prismicArticlesPage.data.articles,
  }

  prismicData.books = prismicData.books.map(item => {
    return {
      buyLinkLabel1: item.buy_button_1_label.text,
      buyLinkLabel2: item.buy_button_2_label.text,
      buyLinkUrl1: item.buy_button_1_url.url,
      buyLinkUrl2: item.buy_button_2_url.url,
      dimensions: item.dimensions.text,
      imgAlt: item.book_cover_image.thumbnails.w1000.alt,
      imgSrc: item.book_cover_image.thumbnails.w1000.url,
      isbn: item.isbn_number.text,
      numPages: item.number_of_pages.text,
      publisher: item.publisher.text,
      showBook: item.show_book,
      summary: item.book_summary.html,
      title: item.book_title.text,
      weight: item.weight.text,
    }
  })
  prismicData.books = prismicData.books.filter(item => item.showBook)
  prismicData.books = prismicData.books.reverse()

  prismicData.articles = prismicData.articles.map(item => {
    return {
      title: item.article_title.text,
      fullUrl: item.article_image.thumbnails.large.url,
      thumbnailUrl: item.article_image.thumbnails.thumbnail.url,
      orientation: item.image_orientation_landscape ? 'landscape' : 'portrait',
      supplementary_info: '',
      externalUrl: item.external_url.url,
      showArticle: item.show_in_articles_list,
    }
  })
  prismicData.articles = prismicData.articles.filter(item => item.showArticle)
  prismicData.articles = prismicData.articles.reverse()

  return (
    <Layout pageName="publications" documentTitlePrefix="Publications">
      <PageHeading heading={prismicData.booksHeading} />
      <Container>
        {prismicData.books &&
          prismicData.books.map((item, index) => (
            <Fade key={`book-${index}`}>
              <Book
                buyLinkLabel1={item.buyLinkLabel1}
                buyLinkLabel2={item.buyLinkLabel2}
                buyLinkUrl1={item.buyLinkUrl1}
                buyLinkUrl2={item.buyLinkUrl2}
                dimensions={item.dimensions}
                imgAlt={item.imgAlt}
                imgSrc={item.imgSrc}
                isbn={item.isbn}
                numPages={item.numPages}
                publisher={item.publisher}
                summary={item.summary}
                title={item.title}
                weight={item.weight}
              />
            </Fade>
          ))}
      </Container>
      <PageHeading heading={prismicData.articlesHeading} />
      <GalleryGrid staticImagesFolderName="" imageData={prismicData.articles} fallbackImgUrl={prismicData.fallbackImgUrl} />
      <ScrollToTop />
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    prismicBooksPage {
      data {
        page_heading {
          text
        }
        books {
          show_book
          book_cover_image {
            thumbnails {
              w1000 {
                url
                alt
              }
            }
          }
          book_summary {
            html
          }
          book_title {
            text
          }
          buy_button_1_label {
            text
          }
          buy_button_1_url {
            url
          }
          buy_button_2_label {
            text
          }
          buy_button_2_url {
            url
          }
          dimensions {
            text
          }
          isbn_number {
            text
          }
          number_of_pages {
            text
          }
          publisher {
            text
          }
          weight {
            text
          }
        }
      }
    }
    prismicArticlesPage {
      data {
        page_heading {
          text
        }
        body_copy {
          html
        }
        fallback_image {
          thumbnails {
            thumbnail {
              url
            }
          }
        }
        articles {
          article_title {
            text
          }
          external_url {
            url
          }
          article_image {
            thumbnails {
              thumbnail {
                url
              }
              large {
                url
              }
            }
            url
          }
          image_orientation_landscape
          show_in_articles_list
        }
      }
    }
  }
`
