import {PATH_TO_STATIC_IMAGES} from './../constants/constants'

export const getImageSrc = (absoluteUrl, staticImagesFolderName, imageFilename) => {
  if (absoluteUrl) {
    return absoluteUrl
  } else if (staticImagesFolderName) {
    return `${PATH_TO_STATIC_IMAGES}${staticImagesFolderName}/${imageFilename}`
  } else {
    return imageFilename
  }
}
