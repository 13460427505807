import React from 'react'
import styled from 'styled-components'

export default function StephenHawkingInfo({heading}) {
  return (
    <StyledComponent className="stephen-hawking-info">
      <p>In 2006 Stephen Hawking commissioned and gifted his portrait by Fred Cuming to The National Portrait Gallery.</p>
      <blockquote>
        <p>
          Thank-you for truly seeing Stephen, and being able to portray that onto canvas. You have captured his spirit, his inner strength, his
          dignity.
        </p>
        <footer>Elaine &amp; Stephen Hawking</footer>
      </blockquote>
    </StyledComponent>
  )
}

const StyledComponent = styled.div`
  font-weight: 100;
  height: 100%;
  margin: 0 auto;
  max-width: 27.2rem;
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  &:after {
    background-color: #909090;
    bottom: 0;
    content: '';
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 4rem;
  }

  p {
    padding-bottom: 4rem;
    position: relative;
    width: 100%;
    margin-bottom: 0;
    line-height: 1.9;

    &::after {
      content: '';
      display: block;
      background-color: #909090;
      height: 2px;
      width: 4rem;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  blockquote {
    margin: 0;
    padding-bottom: 4rem;
    position: relative;

    p {
      font-style: italic;
      margin-bottom: 2rem;
      padding-bottom: 0;
      padding-top: 4rem;

      &::before {
        content: '“';
      }

      &::after {
        background-color: transparent;
        content: '”';
        display: inline;
        position: static;
      }
    }
  }
`
